<template>
  <table class="table table-borderless table-sm text-sm">
    <tbody>
      <tr>
        <th scope="row">Goal</th>
        <td class="text-right no-wrap" :class="{ 'text-success': amount >= goal }" :title="goal">
          {{ formatAmountStat(goal) }}
        </td>
      </tr>
      <tr>
        <th scope="row">Donated Amount</th>
        <td class="text-right no-wrap" :title="amount">
          {{ formatAmountStat(amount) }}
        </td>
      </tr>
      <tr>
        <th scope="row">Num of Donations</th>
        <td class="text-right no-wrap" :title="donations">
          {{ formatNumberStat(donations) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import hasStatistics from 'mixins/hasStatistics';

export default {
  name: 'CardTotalsTable',
  mixins: [hasStatistics],
  props: {
    goal: {
      type: Number,
      required: false,
    },
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
    donations: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
