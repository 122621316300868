<template>
  <div class="card-group text-center">
    <div class="card">
      <div class="card-header p-1">From</div>
      <div class="card-body p-1">
        <p class="card-title mb-0">{{ formatDate(starts_at, getPrimaryFormat()) }}</p>
        <small class="card-text">{{ formatDate(starts_at, getSecondaryFormat()) }}</small>
        <small v-if="show === 'day'" class="card-text">
          {{ formatDate(starts_at, 'YYYY') }}
        </small>
      </div>
    </div>
    <div class="card">
      <div class="card-header p-1">To</div>
      <div class="card-body p-1">
        <p class="card-title mb-0">{{ formatDate(ends_at, getPrimaryFormat()) }}</p>
        <small class="card-text">{{ formatDate(ends_at, getSecondaryFormat()) }}</small>
        <small v-if="show === 'day'" class="card-text">
          {{ formatDate(ends_at, 'YYYY') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import hasTimeDate from '../mixins/hasTimeDate';

export default {
  name: 'FromTo',
  mixins: [hasTimeDate],
  props: {
    ends_at: {
      type: String,
      required: false,
      default: '',
    },
    starts_at: {
      type: String,
      required: false,
      default: '',
    },
    show: {
      type: String,
      required: false,
      default: 'day',
    },
  },
  methods: {
    getPrimaryFormat() {
      return this.show === 'day' ? 'D' : 'h:mm A';
    },
    getSecondaryFormat() {
      return this.show === 'day' ? 'MMM' : 'MMM D, YYYY';
    },
  },
};
</script>

<style scoped lang="scss">
small.card-text {
  display: block;
}
</style>
