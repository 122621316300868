<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />
    <fieldset :disabled="isLocked">
      <div class="form-group">
        <div class="row">
          <div class="col-12 col-sm-8">
            <label for="cf-title">Campaign Title <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': hasError('title') }"
              id="cf-title"
              name="title"
              aria-describedby="titleHelp"
              required
              v-model="campaign.title"
            />
            <small id="titleHelp" class="form-text text-muted">
              The Title of the campaign that will be shown throughout the application.
            </small>
          </div>
          <div class="col-12 col-sm-4">
            <label for="cf-remote-id">Remote ID <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              id="cf-remote-id"
              name="remote_id"
              required
              v-model="campaign.remote_id"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="cf-description">Description</label>
        <textarea
          class="form-control"
          :class="{ 'is-invalid': hasError('description') }"
          id="cf-description"
          name="description"
          aria-describedby="descriptionHelp"
          v-model="campaign.description"
        >
        </textarea>
        <small id="descriptionHelp" class="form-text text-muted">
          A brief description about this campaign.
        </small>
      </div>

      <div class="form-group">
        <label for="cf-type">Type</label>
        <Select2
          v-model="campaign.type"
          :options="types"
          :settings="{ allowClear: true, theme: 'bootstrap4', width: '100%' }"
          :class="{ 'is-invalid': hasError('type') }"
          id="cf-type"
          name="type"
          aria-describedby="typeHelp"
        />
        <small id="typeHelp" class="form-text text-muted">Select a type for the campaign.</small>
      </div>

      <div v-if="false" class="form-group">
        <label for="cf-type">Form IDs</label>
        <Select2
          v-model="meta.formIds"
          :options="formIdOptions"
          :settings="select2Settings"
          aria-describedby="formIdHelp"
        />
        <small id="formIdHelp" class="form-text text-muted">Enter all applicable form IDs.</small>
      </div>

      <div class="form-group">
        <label for="cf-type">Forms</label>
        <div class="row align-items-end">
          <div class="col">
            <div v-for="(form, index) in meta.forms" :key="index" class="form-row">
              <div class="col-3">
                <input
                  v-model="meta.forms[index].id"
                  :id="`cf-form-id-${index}`"
                  class="form-control"
                  placeholder="Form ID"
                  aria-label="Form ID"
                />
              </div>
              <div class="col flex-grow-1 flex-shrink-0">
                <select
                  class="form-control"
                  v-model="meta.forms[index].label"
                  @change="updateFormType($event, ind)"
                >
                  <option value="" readonly>- Select -</option>
                  <option
                    v-for="(type, i) in formTypeOptions"
                    :key="i"
                    :value="type.id"
                    :selected="meta.forms[index].label == type.id"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
              <div class="col flex-grow-0 flex-shrink-1">
                <button
                  class="btn btn-outline-danger"
                  type="button"
                  aria-label="Remove Form"
                  @click="removeForm(form.id, index)"
                >
                  <font-awesome-icon icon="minus-circle" /> <span class="sr-only">Remove Form</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col flex-grow-0 flex-shrink-1">
            <button
              class="btn btn-success"
              type="button"
              aria-label="Add Form"
              @click.prevent="addForm"
            >
              <font-awesome-icon icon="plus-circle" /> <span class="sr-only">Add Form</span>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="cf-target">Campaign Targets <span class="text-danger">*</span></label>
        <div class="row">
          <div class="col-4">
            <label class="text-sm">All Donations</label>
            <money
              v-bind="money"
              class="form-control"
              :class="{ 'is-invalid': hasError('target') }"
              id="cf-target-all"
              name="target"
              v-model="meta.targets.all"
            ></money>
            <small id="targetHelp" class="form-text text-muted">
              The Target or Goal in dollars that this Campaign is trying to raise.
            </small>
          </div>
          <div class="col-4">
            <label class="text-sm">Single Donations</label>
            <money
              v-bind="money"
              class="form-control"
              :class="{ 'is-invalid': hasError('target') }"
              id="cf-target-single"
              name="target"
              v-model="meta.targets.single"
            ></money>
            <small id="singleTargetHelp" class="form-text text-muted">
              The target/goal for single donations.
            </small>
          </div>
          <div class="col-4">
            <label class="text-sm">Recurring Donations</label>
            <money
              v-bind="money"
              class="form-control"
              :class="{ 'is-invalid': hasError('target') }"
              id="cf-target-recurring"
              name="target"
              v-model="meta.targets.recurring"
            ></money>
            <small id="recurringTargetHelp" class="form-text text-muted">
              The target/goal for recurring donations.
            </small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Campaign Dates <span class="text-danger">*</span></label>
        <v-date-picker v-model.range="range">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="row">
              <div class="col-6">
                <label class="text-sm">Start Date</label>
                <input
                  id="cf-date-range-start"
                  class="form-control"
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  required
                />
              </div>
              <div class="col-6">
                <label class="text-sm">End Date</label>
                <input
                  id="cf-date-range-end"
                  class="form-control"
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  required
                />
              </div>
            </div>
          </template>
        </v-date-picker>
        <small id="campaignDatesHelp" class="form-text text-muted">
          How long will this campaign run?
        </small>
      </div>
    </fieldset>

    <div class="form-row justify-content-between">
      <button type="submit" class="btn btn-success" :disabled="isLocked">
        <font-awesome-icon icon="save" /> Save
      </button>
      <button type="button" class="btn btn-outline-danger" :disabled="isLocked" @click="modalClose">
        <font-awesome-icon icon="window-close" /> Cancel
      </button>
    </div>
  </form>
</template>

<script>
/*global $*/
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { Money3Component } from 'v-money3';
import helpers from 'mixins/helpers';
import formHelpers from 'mixins/formHelpers';
import FormError from './FormError';

export default {
  name: 'CampaignForm',
  components: { FormError, money: Money3Component },
  mixins: [formHelpers, helpers],
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      allowedFields: [
        'campaign_id',
        'remote_id',
        'type',
        'title',
        'description',
        'starts_at',
        'ends_at',
        'target',
        'meta',
      ],
      campaign: {}, // this.initializeCampaign(),
      error: false,
      isLocked: false,
      isUploadng: false,
      money: {
        precision: 0,
        prefix: '$ ',
        masked: true,
      },
      remote_id: '',
      range: {
        start: '',
        end: '',
      },
      targets: {
        all: 0,
        single: 0,
        recurring: 0,
      },
      types: [
        {
          id: 'fundraising',
          text: 'Fundraising',
        },
        {
          id: 'disposal',
          text: 'Disposal',
        },
        {
          id: 'labor',
          text: 'Labor',
        },
      ],
      formIdOptions: [],
      formTypeOptions: [
        {
          id: 'phone',
          text: 'Phone',
        },
        {
          id: 'web',
          text: 'Web',
        },
        {
          id: 'email',
          text: 'Email',
        },
        {
          id: 'text',
          text: 'Text/SMS',
        },
        {
          id: 'app',
          text: 'App',
        },
        {
          id: 'overflow',
          text: 'Overflow',
        },
      ],
      formTypeSelectOptions: {
        allowClear: true,
        theme: 'bootstrap4',
        width: '100%',
      },
      meta: {
        forms: [],
        formIds: [],
        targets: {
          all: 0,
          single: 0,
          recurring: 0,
        },
      },
    };
  },
  created() {
    this.campaign = this.initializeCampaign();

    if (typeof this.initial.meta === 'string' || typeof this.initial.meta === 'object') {
      this.meta = Object.assign(this.meta, this.maybeParseJson(this.initial.meta));
    }

    this.remote_id = this.campaign.remote_id;
    this.resetRange();

    if (this.hasProperty(this.meta, 'formIds') && this.meta.formIds.length) {
      this.formIdOptions = this.meta.formIds;

      this.meta.formIds.map((id) => {
        const forms = this.meta.forms.filter((form) => {
          return id == form.id;
        });

        if (forms.length == 0) {
          this.meta.forms.push({
            id,
            label: '',
          });
        }
      });
    }

    this.select2Settings = Object.assign(this.select2Settings, {
      multiple: true,
      tags: true,
    });
  },
  computed: {
    defaultRange() {
      const now = dayjs();
      const weekFromNow = now.add(7, 'day');
      return {
        start: this.initial.starts_at || now.toDate(),
        end: this.initial.ends_at || weekFromNow.toDate(),
      };
    },
  },
  watch: {
    range(newValue) {
      if (newValue) {
        this.onDateChange(newValue);
      }
    },
  },
  methods: {
    resetRange() {
      this.range.start = this.defaultRange.start;
      this.range.end = this.defaultRange.end;
    },
    addForm() {
      this.meta.forms.push({
        id: '',
        label: '',
      });
    },
    removeForm(formId, index) {
      index = index || false;

      Object.keys(this.meta.forms).map((key) => {
        if (formId == this.meta.forms[key].id) {
          index = key;
        }
      });

      if (index !== false) {
        this.meta.forms.splice(index, 1);
        const formIdsIndex = this.meta.formIds.indexOf(formId);

        if (formIdsIndex) {
          this.meta.formIds.splice(formIdsIndex, 1);
        }
      }
    },
    updateFormType(event, index) {
      this.meta.forms[index].label = event.target.value;
    },
    hasError(field) {
      if (this.error && this.error.errorBag) {
        // Check if the error bag has an entry for this
        return _.has(this.error.errorBag, field);
      }

      return false;
    },
    initializeCampaign() {
      const defaultCampaign = {
        title: '',
        remote_id: '',
        description: '',
        type: 'fundraising',
        target: 0,
        starts_at: this.defaultRange.start,
        ends_at: this.defaultRange.end,
        meta: this.meta,
      };

      return Object.assign(defaultCampaign, this.initial);
    },
    handleSubmit() {
      // console.log('CampaignForm handleSubmit()', this.campaign);
      // Lock the buttons
      this.isLocked = true;
      this.isUploadng = true;

      const payload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          // We are setting 'PUT' here because we are replacing the entire object. Future iterations may only adjust
          // some parts of the model.
          method: 'PUT',
        },
      };
      // Set the URL to either create or update
      let url;
      if (this.isNew()) {
        url = 'campaigns/create';
      } else {
        url = 'campaigns/update';
        payload.options.id = this.campaign.campaign_id;
      }

      // console.log('CampaignForm handleSubmit() before :url :payload', { url, payload });

      // Send to action
      this.$store
        .dispatch(url, payload)
        .then(() => {
          // on success, close modal, unlock buttons
          // console.log('CampaignForm handleSubmit() then', response);
          this.$emit('complete');
          this.modalClose();
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('CampaignForm handleSubmit() catch', error);
          this.error = this.$errorProcessor(error);

          // reset values
          if (!this.hasProperty(this.campaign, 'remote_id')) {
            this.campaign.remote_id = this.remote_id;
          }
          if (typeof this.campaign.meta === 'string') {
            this.campaign.meta = JSON.parse(this.campaign.meta);
          }
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    modalClose() {
      // reset the Campaign
      this.campaign = this.initializeCampaign();
      this.error = false;
      this.isLocked = false;
      this.resetRange();

      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    onDateChange(range) {
      if (range) {
        this.campaign.starts_at = dayjs(range.start).startOf('day').toISOString();
        this.campaign.ends_at = dayjs(range.end).endOf('day').toISOString();
      }
    },
    prepareData() {
      // don't send remote_id if no change
      // #TODO review if this step is still needed
      if (this.initial.remote_id === this.campaign.remote_id) {
        // console.log('CampaignForm methods prepareDate() :remote_id not sent');
        delete this.campaign.remote_id;
      }

      // We just need to make sure the campaign.target is a number
      this.campaign.target = _.isNumber(this.meta.targets.all)
        ? this.meta.targets.all
        : Number(this.meta.targets.all.replace(/\s/g, '').replace('$', '').replace(/,/g, ''));

      // setup targets meta data
      Object.keys(this.meta.targets).forEach((key) => {
        this.meta.targets[key] = _.isNumber(this.meta.targets[key])
          ? this.meta.targets[key]
          : Number(this.meta.targets[key].replace(/\s/g, '').replace('$', '').replace(/,/g, ''));
      });

      // cleanup forms
      const forms = this.meta.forms.filter((form) => {
        if (this.hasProperty(form, 'id') && form.id) {
          this.meta.formIds.push(form.id);

          return true;
        }

        return false;
      });

      this.meta.forms = forms;

      // cleanup form IDs for legacy code stuffs
      this.meta.formIds = [...new Set(this.meta.formIds)];

      // convert campaign.meta to string to send over
      this.campaign.meta = JSON.stringify(this.meta);

      return this.campaign;
    },
    select2TemplateResult(state) {
      console.log(state);
    },
  },
};
</script>

<style scoped lang="scss">
.col-10 .form-group:last-child {
  margin-bottom: 0;
}
.form-row + .form-row {
  margin-top: 0.5rem;
}
.input-group input[id^='cf-form-id'] {
  flex: 0 0 25%;
}
</style>
