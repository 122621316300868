<template>
  <div class="campaign-stats">
    <div class="table-responsive">
      <table class="table table-borderless table-sm text-sm">
        <thead>
          <tr>
            <th></th>
            <th class="text-center">Donations</th>
            <th class="text-center">$/Hr</th>
            <th class="text-right">Total Donated</th>
            <th class="text-right">% of Total</th>
            <th class="text-right">Average</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">All</th>
            <td class="text-center" :title="totalDonations">
              {{ formatNumberStat(totalDonations, '0,0') }}
            </td>
            <td class="text-center no-wrap" :title="donationsPerHour">
              {{ formatAmountStat(donationsPerHour, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap" :title="totalDollars">
              {{ formatAmountStat(totalDollars, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap">{{ formatPercentStat(percentage / 100, '0,0%') }}</td>
            <td class="text-right no-wrap" :class="averageMovement('all')">
              {{ donationsAverage('all', '$ 0,0') }}
              <font-awesome-icon :icon="averageMovementIcon(averageMovement('all'))" class="ml-2" />
            </td>
          </tr>
          <tr>
            <th scope="row">Single</th>
            <td class="text-center" :title="totalDonationsSingle">
              {{ formatNumberStat(totalDonationsSingle, '0,0') }}
            </td>
            <td class="text-center no-wrap" :title="donationsPerHourSingle">
              {{ formatAmountStat(donationsPerHourSingle, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap" :title="totalDollarsSingle">
              {{ formatAmountStat(totalDollarsSingle, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap">
              {{ formatPercentStat(percentageSingle / 100, '0,0%') }}
            </td>
            <td class="text-right no-wrap" :class="averageMovement('single')">
              {{ donationsAverage('single', '$ 0,0') }}
              <font-awesome-icon
                :icon="averageMovementIcon(averageMovement('single'))"
                class="ml-2"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Reccurring</th>
            <td class="text-center" :title="totalDonationsRecurring">
              {{ formatNumberStat(totalDonationsRecurring, '0,0') }}
            </td>
            <td class="text-center no-wrap" :title="donationsPerHourRecurring">
              {{ formatAmountStat(donationsPerHourRecurring, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap">
              {{ formatAmountStat(totalDollarsRecurring, '$ 0,0') }}
            </td>
            <td class="text-right no-wrap">
              {{ formatPercentStat(percentageRecurring / 100, '0,0%') }}
            </td>
            <td class="text-right no-wrap" :class="averageMovement('recurring')">
              {{ donationsAverage('recurring', '$ 0,0') }}
              <font-awesome-icon
                :icon="averageMovementIcon(averageMovement('recurring'))"
                class="ml-2"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';

export default {
  name: 'CampaignStats',
  mixins: [hasStatistics, hasTimeDate],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    donations: {
      type: [Object, Array],
      required: false,
      default: () => [],
    },
    pledges: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    percentage() {
      if (this.statistics) {
        return Math.round(
          ((this.statistics.total_sum + this.statistics.pledges_total_sum) / this.targets.all) * 100
        );
      }

      return 0;
    },
    percentageSingle() {
      if (!this.statistics) {
        if (this.singlePromotion.intentions) {
          if (this.singlePromotion.intentions[0].counter) {
            return (this.totalDollarsSingle / this.singlePromotion.intentions[0].counter) * 100;
          }
        }
      }

      if (this.targets.single) {
        return (
          ((this.statistics.total_single_sum + this.statistics.pledges_total_sum) /
            this.targets.single) *
          100
        );
      }

      return 0;
    },
    percentageRecurring() {
      if (!this.statistics) {
        if (this.recurringPromotion.intentions) {
          if (this.recurringPromotion.intentions[0].counter) {
            return (
              (this.totalDollarsRecurring / this.recurringPromotion.intentions[0].counter) * 100
            );
          }
        }
      }

      if (this.targets.recurring) {
        return (this.statistics.total_recurring_sum / this.targets.recurring) * 100;
      }

      return 0;
    },
    statistics() {
      return this.campaign.statistics ?? {};
    },
    targets() {
      if (this.campaign.meta) {
        // console.log('CampaignStats targets() hasCampaignMeta');
        if (this.campaign.meta.targets) {
          // console.log('CampaignStats targets() hasCampaignMetaTargets', this.campaign.meta.targets);
          const targets = Object.assign({});

          Object.keys(this.campaign.meta.targets).forEach((key) => {
            let target = this.campaign.meta.targets[key].toString();
            // console.log('CampaignStats created() targets', key, target);
            targets[key] = Number(target.replace(/\s/g, '').replace('$', '').replace(/,/g, ''));
          });

          return targets;
        }
      }

      return {
        all: 0,
        single: 0,
        recurring: 0,
      };
    },
  },
};
</script>

<style scoped></style>
