<template>
  <div
    class="card promotion-card mb-4 w-100"
    :class="{
      'border-primary': isCurrent && hasIntention,
      'border-danger': !hasIntention,
    }"
  >
    <div v-if="error" class="card-body">
      <Error :error="error" />
    </div>
    <div v-else-if="isLoading" class="card-body">
      <Loading />
    </div>
    <template v-else>
      <div class="card-body card-body-extend">
        <h5 class="card-title">
          <router-link :to="{ name: 'promotion', params: { id: promotion.promotion_id } }">
            {{ promotion.title }}
          </router-link>
        </h5>
        <div v-if="!hasIntention" class="alert alert-danger" role="alert">
          <div>
            <router-link
              :to="{ name: 'promotion', params: { id: promotion.promotion_id } }"
              class="btn btn-danger ml-2"
            >
              View
            </router-link>
            <p class="card-text">
              <strong>Heads up!</strong> This promotion is missing an <em>Intention</em>. Please
              edit the promotion and finish creating the <em>Intention</em>.
            </p>
          </div>
        </div>
        <p class="card-text">
          {{ promotion.description }}
        </p>
        <p class="card-text" v-if="showCampaign && can('view', 'campaigns')">
          <strong>Campaign</strong>:
          <router-link
            :to="{
              name: 'campaign',
              params: {
                id: promotion.campaign.campaign_id,
              },
            }"
          >
            {{ campaign ? campaign.title : 'N/A' }}
          </router-link>
        </p>
      </div>

      <div class="card-body">
        <!-- <CardTotalsVertical
          :goal="intention.counter"
          :amount="totalDollars"
          :donations="totalDonations"
        /> -->
        <CardTotalsTable
          :goal="intention.counter"
          :amount="totalDollars"
          :donations="totalDonations"
        />
        <FromTo :starts_at="promotion.starts_at" :ends_at="promotion.ends_at" show="time" />
      </div>
    </template>

    <div class="card-footer">
      <router-link
        v-if="can('view', 'promotions')"
        :to="{ name: 'promotion', params: { id: promotion.promotion_id } }"
        class="btn btn-primary btn-sm mr-2"
      >
        View
      </router-link>
      <!--  sef is an abbreviation for showEditForm -->
      <router-link
        v-if="can('update', 'promotions')"
        :to="{
          name: 'promotion',
          params: {
            id: promotion.promotion_id,
          },
          query: { sef: true },
        }"
        class="btn btn-secondary btn-sm mr-2"
      >
        Edit
      </router-link>
      <small class="text-muted">
        Updated:
        <span :title="fullDateTime(promotion.updated_at)">{{ timeAgo(promotion.updated_at) }}</span>
      </small>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import hasIntentions from 'mixins/hasIntentions';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
// import CardTotalsVertical from 'components/CardTotalsVertical';
import CardTotalsTable from 'components/CardTotalsTable';
import Error from 'components/Error';
import FromTo from 'components/FromTo';
import Loading from 'components/Loading';

export default {
  name: 'PromotionCard',
  components: {
    // CardTotalsVertical,
    CardTotalsTable,
    Error,
    FromTo,
    Loading,
  },
  mixins: [hasIntentions, hasStatistics, hasTimeDate, helpers],
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showCampaign: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      donations: [],
      donationsMeta: {},
      error: null,
      isLoading: false,
    };
  },
  computed: {
    // campaign() {
    //   // console.log('PromotionCard computed campaign()', this.promotion.campaign);
    //   return this.promotion.campaign;
    // },
    campaignId() {
      if (this.hasProperty(this.campaign, 'campaign_id')) {
        return this.campaign.campaign_id;
      }

      return this.promotion.campaign_id;
    },
    isCurrent() {
      // Is this Promotion currently running?
      return this.isBetween(this.promotion.starts_at, this.promotion.ends_at);
    },
    statistics() {
      return this.promotion.statistics ? this.promotion.statistics : {};
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = null;
      this.isLoading = true;

      const { ends_at, starts_at } = this.promotion;
      const payload = {
        id: this.campaignId,
        params: {
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
        },
        saveToStore: false,
      };

      const where = this.intentionTrackedDonationType();

      if (where) {
        payload.params.where = where;
      }

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('PromotionCard fetchData() campaigns/getDonations then', payload, response.data);
          const { data, meta } = response.data;
          this.donations = data;
          this.donationsMeta = meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },
  },
};
</script>

<style scoped>
.alert-danger > div {
  display: flex;
  align-items: center;
}

.alert-danger .btn-danger {
  flex: 0 1 auto;
  order: 2;
}

.alert-danger :last-child {
  margin-bottom: 0;
}
</style>
