<template>
  <tr>
    <td scope="row">
      <router-link :to="{ name: 'pledge', params: { id: pledge.pledge_id } }">
        {{ pledge.pledge_id }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'pledge', params: { id: pledge.pledge_id } }">
        {{ fullName }}
      </router-link>
    </td>
    <td class="text-center">
      <font-awesome-icon v-if="pledge.anon" icon="check-circle" class="text-success" />
    </td>
    <td>{{ pledge.email }}</td>
    <td>{{ pledge.phone }}</td>
    <td>$ {{ pledge.amount }}</td>
    <td>$ {{ pledge.total }}</td>
    <!--
    <td class="text-center">
      <router-link
        v-if="pledge.campaign_id"
        :to="{ name: 'campaign', params: { id: pledge.campaign_id } }"
      >
        {{ pledge.campaign_id }}
      </router-link>
    </td>
    -->
    <!--
    <td class="text-center">
      <router-link
        v-if="pledge.promotion_id"
        :to="{ name: 'promotion', params: { id: pledge.promotion_id } }"
      >
        {{ pledge.promotion_id }}
      </router-link>
    </td>
    -->
  </tr>
</template>

<script>
export default {
  name: 'PledgesListRow',
  props: {
    pledge: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    fullName() {
      // if (this.pledge.anon) {
      //   return '- Friend -';
      // }

      if (this.pledge.display_as) {
        return this.pledge.display_as;
      }

      return [this.pledge.first, this.pledge.last].join(' ');
    },
  },
  methods: {},
};
</script>
