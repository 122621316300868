const hasIntentions = {
  computed: {
    intention() {
      // For now, only return the first one. It should be the only one.
      // #TODO this behavior may change in the future
      return this.intentions[0] || {};
    },
    intentionMeta() {
      if (!Object.prototype.hasOwnProperty.call(this.intention, 'meta')) {
        return {};
      }

      if (typeof this.intention.meta === 'string') {
        return JSON.parse(this.intention.meta);
      }

      return this.intention.meta;
    },
    intentions() {
      // Making an assumption here that only a promotion has an intention
      return this.promotion.intentions ?? [];
    },
    hasIntention() {
      return this.promotion.intentions.length > 0;
    },
  },
  methods: {
    intentionTrackedDonationType(where) {
      where = where || [];
      // console.log('hasIntentions.js methods intentionTrackedDonationType() :where', where);
      if (this.intentionMeta) {
        if (this.intentionMeta.tracked_donation_type) {
          if (this.intentionMeta.tracked_donation_type === 'single') {
            where.push('recurring,false');
          } else if (this.intentionMeta.tracked_donation_type === 'recurring') {
            where.push('recurring,true');
          }
        }
      }

      return where.join('|');
    },
    /**
     * Is the current Intention type the same as passed in an argument. `types`
     * can be either an array of types, or a string.
     *
     * @param {String|Array} types
     * @returns {boolean}
     */
    intentionIs(types) {
      // console.log('hasIntentions.js methods intentionIs() :types', types);
      if (Array.isArray(types)) {
        return types.includes(this.intentionType());
      } else {
        return this.intentionType() === types;
      }
    },
    intentionType() {
      return this.intention.type;
    },
    intentionTypeLabel() {
      // console.log('hasIntentions.js methods intentionTypeLabel()', this.intention.type);
      switch (this.intention.type) {
        case 'giveaway':
          return 'Giveaways';
        case 'matching':
          return 'Match';
        case 'target':
          return this.intention.countable === 'amount' ? 'Goal/$' : 'Goal/ Num of Donations';
      }
    },
  },
};

// leave the export, even if you don't use it
export default hasIntentions;
