<template>
  <tr>
    <template v-if="error || isLoading">
      <td :colspan="can('index', 'favorite_comments') ? 9 : 8">
        <Error v-if="error" :error="error" />
        <Loading v-else-if="isLoading" />
      </td>
    </template>
    <template v-else>
      <td v-if="can('index', 'favorite_comments')">
        <FavoriteComment :donation_id="donation.donation_id" :donor_id="donation.donor_id" />
      </td>
      <td>
        <router-link
          :to="{ name: 'donation', params: { id: donation.donation_id } }"
          v-if="can('view', 'donations')"
        >
          {{ donation.donation_id }}
        </router-link>
        <template v-else>
          {{ donation.donation_id }}
        </template>
      </td>
      <td class="no-wrap">
        <!-- <router-link
          :to="{ name: 'donor', params: { id: donation.donor_id } }"
          v-if="can('view', 'donors')"
          v-html="displayAs"
        >
          <font-awesome-icon v-if="false" icon="external-link-alt" class="text-sm" />
        </router-link>
        <template v-else v-html="displayAs" /> -->
        <span v-html="displayAs"></span>
      </td>
      <td :title="`ID: ${donation.form_id}`">{{ formLabel }}</td>
      <td class="has-money" :title="`$ ${amount}`">{{ formatAmountStat(amount) }}</td>
      <td align="right">
        <font-awesome-icon
          v-if="donation.recurring"
          icon="redo"
          class="text-warning"
          title="Recurring Donation"
        />
        <font-awesome-icon
          v-else
          icon="money-bill-alt"
          class="text-success"
          title="Single Donation"
        />
      </td>
      <td class="no-wrap">
        {{ fullDateTime(donation.payment_made_at) }}
      </td>
      <td class="dont-break-out" v-html="comments"></td>
      <td :title="deletedAt" style="text-align: center">
        <button
          v-if="can('destroy', 'donations') && !isDeleted(donation)"
          class="btn btn-sm btn-danger"
          type="button"
          @click="$emit('deleteDonation', donation.donation_id)"
        >
          <font-awesome-icon icon="trash" />
        </button>
        <button
          v-else-if="can('update', 'donations') && isDeleted(donation)"
          class="btn btn-sm btn-success"
          type="button"
          @click="$emit('restoreDonation', donation.donation_id)"
        >
          <font-awesome-icon icon="trash-restore" />
        </button>
        <template v-else>
          <font-awesome-icon icon="trash" :title="`Deleted at ${deletedAt}`" />
        </template>
      </td>
    </template>
  </tr>
</template>

<script>
import * as _ from 'lodash';
// import dayjs from 'dayjs';
import helpers from 'mixins/helpers';
import hasTimeDate from 'mixins/hasTimeDate';
import hasStatistics from 'mixins/hasStatistics';
import Error from 'components/Error';
import FavoriteComment from 'components/favorite-comments/FavoriteComment';
import Loading from 'components/Loading';

export default {
  name: 'DonationsListRow',
  components: { Error, FavoriteComment, Loading },
  mixins: [hasStatistics, hasTimeDate, helpers],
  props: {
    donation: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    campaign: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
    };
  },
  computed: {
    amount() {
      return this.donation.amount.toFixed(2);
    },
    comments() {
      return this.donation.comments ? this.donation.comments.replace('&amp;', '&') : '-';
    },
    deletedAt() {
      return this.hasProperty(this.donation, 'deleted_at') ? this.donation.deleted_at : '';
    },
    displayAs() {
      if (this.donation.anon) {
        return 'Friend';
      }

      if (this.can('view', 'donors')) {
        if (this.donation.display_as) {
          return this.donation.display_as;
        }

        return [this.donation.donor.first, this.donation.donor.last].join(' ');
      }

      return this.donation.donor.first;
    },
    formLabel() {
      let formLabel = this.donation.form_id;

      if (this.hasProperty(this.campaign, 'meta')) {
        if (this.hasProperty(this.campaign.meta, 'forms') && this.campaign.meta.forms.length > 0) {
          const form = _.find(this.campaign.meta.forms, (f) => f.id == this.donation.form_id);

          if (typeof form != 'undefined' && form.label) {
            formLabel = _.upperFirst(form.label);
          }
        }
      }
      return formLabel;
    },
    // isDeleted() {
    //   if (this.hasProperty(this.donation, 'deleted_at')) {
    //     return dayjs(this.donation.deleted_at).isValid();
    //   }

    //   return false;
    // },
  },
};
</script>

<style lang="scss"></style>
