<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />
    <div class="form-group">
      <label for="cf-logo">Logo</label>
      <div class="input-group">
        <div class="custom-file">
          <input
            class="custom-file-input"
            id="cf-logo"
            type="file"
            name="logo"
            aria-describedby="logoHelp"
            accept="image/*"
            @change="onFileChanged"
          />
          <label class="custom-file-label" for="cf-logo">{{ selectedFilename }}</label>
        </div>
      </div>
      <small id="logoHelp" class="form-text text-muted">
        An image file of the campaign's logo.
      </small>
    </div>
    <div class="form-row justify-content-between">
      <div class="col-auto">
        <button type="submit" class="btn btn-success" :disabled="isLocked">
          <font-awesome-icon icon="trash" /> Save
        </button>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-outline-danger"
          :disabled="isLocked"
          @click="modalClose"
        >
          <font-awesome-icon icon="window-close" /> Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
/*global $*/
import FormError from './FormError';
import formHelpers from 'mixins/formHelpers';

export default {
  name: 'CampaignLogoForm',
  components: { FormError },
  mixins: [formHelpers],
  props: {
    id: {
      type: Number,
      required: true,
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: false,
      isLocked: false,
      selectedFile: null,
      selectedFilename: 'Choose file',
    };
  },
  methods: {
    handleSubmit() {
      console.log('CampaignLogoForm handleSubmit()', this.id);
      // Lock the buttons
      this.isLocked = true;

      const formData = new FormData();
      formData.append('logo', this.selectedFile);

      const payload = {
        id: this.id,
        formData,
      };

      // Send to action
      this.$store
        .dispatch('campaigns/updateLogo', payload)
        .then((response) => {
          // on success, close modal, unlock buttons
          console.log('CampaignLogoForm handleSubmit() then', response);
          this.modalClose();
          this.$router.push({ name: 'campaigns' });
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('CampaignLogoForm handleSubmit() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    modalClose() {
      // reset the Modal
      this.error = false;
      this.isLocked = false;
      this.selectedFile = null;
      this.selectedFilename = 'Choose file';

      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    onFileChanged(event) {
      console.log('CampaignLogoForm methods onFileChanged :event', event);
      this.selectedFile = event.target.files[0];
      this.selectedFilename = event.target.files[0].name;

      console.log('CampaignLogoForm methods onFileChanged', {
        selectedFile: this.selectedFile,
        selectedFilename: this.selectedFilename,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-file {
  cursor: pointer;
}
</style>
