<template>
  <Loading v-if="isLoading" />
  <form v-else @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />

    <fieldset :disabled="isLocked">
      <label>Type of Promotion <span class="text-danger">*</span></label>
      <div class="form-group">
        <div>
          <div class="btn-group btn-group-sm btn-group-toggle">
            <label
              class="btn btn-primary"
              :class="{ active: intention.type === 'target' }"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="bottom"
              title="Goal"
              data-content="Reach a certain dollar amount in donations or a certain number of individual donations"
            >
              <input
                type="radio"
                name="type"
                id="type1"
                value="target"
                v-model="intention.type"
                @change="onTypeChange"
              />
              Goal
            </label>
            <label
              class="btn btn-primary"
              :class="{ active: intention.type === 'giveaway' }"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="bottom"
              title="Giveaway"
              data-content="A number of items to be given away"
            >
              <input
                type="radio"
                name="type"
                id="type2"
                value="giveaway"
                v-model="intention.type"
                @change="onTypeChange"
              />
              Giveaway
            </label>
            <label
              class="btn btn-primary"
              :class="{ active: intention.type === 'matching' }"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="bottom"
              title="Matching"
              data-content="A generous donor has offered to match donations up to a certain amount"
            >
              <input
                type="radio"
                name="type"
                id="type3"
                value="matching"
                v-model="intention.type"
                @change="onTypeChange"
              />
              Matching
            </label>
          </div>
        </div>
        <div v-if="false" id="typeHelp" class="small form-text text-muted">
          <dl>
            <dt>Goal</dt>
            <dd>
              Reach a certain dollar amount in donations or a certain number of individual donations
            </dd>
            <dt>Giveaway</dt>
            <dd>A number of items to be given away</dd>
            <dt>Matching</dt>
            <dd>A generous donor has offered to match donations up to a certain amount</dd>
          </dl>
        </div>
      </div>
    </fieldset>

    <fieldset :disabled="isLocked" v-if="intention.type === 'target'">
      <div class="form-group">
        <label>
          What type of donation do you want to track? <span class="text-danger">*</span>
        </label>
        <div>
          <div class="btn-group btn-group-sm btn-group-toggle">
            <label
              class="btn btn-primary"
              :class="{ active: meta.tracked_donation_type === 'all' }"
            >
              <input
                type="radio"
                name="tracked_donation_type"
                id="tracked_donation_type_all"
                value="all"
                v-model="meta.tracked_donation_type"
              />
              All
            </label>
            <label
              class="btn btn-primary"
              :class="{ active: meta.tracked_donation_type === 'single' }"
            >
              <input
                type="radio"
                name="tracked_donation_type"
                id="tracked_donation_type_single"
                value="single"
                v-model="meta.tracked_donation_type"
              />
              Single
            </label>
            <label
              class="btn btn-primary"
              :class="{ active: meta.tracked_donation_type === 'recurring' }"
            >
              <input
                type="radio"
                name="tracked_donation_type"
                id="tracked_donation_type_recurring"
                value="recurring"
                v-model="meta.tracked_donation_type"
              />
              Recurring
            </label>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset
      :disabled="isLocked"
      v-if="intention.type === 'target' && meta.tracked_donation_type === 'all'"
    >
      <div class="form-group">
        <label>What is the goal? <span class="text-danger">*</span></label>
        <div>
          <div class="btn-group btn-group-sm btn-group-toggle">
            <label class="btn btn-primary" :class="{ active: intention.countable === 'amount' }">
              <input
                type="radio"
                name="countable"
                id="countable2"
                value="amount"
                v-model="intention.countable"
                @change="onCountableChange"
              />
              Amount of Donations
            </label>
            <label class="btn btn-primary" :class="{ active: intention.countable === 'donors' }">
              <input
                type="radio"
                name="countable"
                id="countable1"
                value="donors"
                v-model="intention.countable"
                @change="onCountableChange"
              />
              Number of Donations
            </label>
          </div>
        </div>
        <p
          v-if="intention.countable === 'amount'"
          id="countableHelp"
          class="small form-text text-muted"
        >
          During this promotion we can measure the total dollar amount collected.
        </p>
        <p
          v-if="intention.countable === 'donors'"
          id="countableHelp"
          class="small form-text text-muted"
        >
          During this promotion we can measure the number of individual donations.
        </p>
      </div>
      <div class="form-group">
        <label for="i-counter-count">
          {{ goalCounterLabel }} <span class="text-danger">*</span>
        </label>
        <money
          v-bind="money"
          v-if="intention.countable === 'amount'"
          class="form-control"
          id="i-counter-amount"
          name="counter"
          v-model="intention.counter"
          @keyup="onTrackingChange"
        ></money>
        <small
          v-if="intention.countable === 'amount'"
          id="counterAmountHelp"
          class="form-text text-muted"
        >
          Must be an amount greater than $0.
        </small>
        <input
          v-if="intention.countable === 'donors'"
          type="number"
          class="form-control"
          id="i-counter-count"
          name="counter"
          aria-describedby="counterHelp"
          required
          v-model="intention.counter"
          @keyup="onTrackingChange"
        />
        <small
          v-if="intention.countable === 'donors'"
          id="counterAmountHelp"
          class="form-text text-muted"
        >
          Must be a value greater than 0.
        </small>
      </div>
    </fieldset>

    <fieldset :disabled="isLocked" v-if="intention.type === 'giveaway'">
      <div class="form-group">
        <label>How many items are we giving away? <span class="text-danger">*</span></label>
        <input
          type="number"
          class="form-control"
          id="i-giveaway-counter-count"
          name="counter"
          aria-describedby="counterHelp"
          required
          v-model="intention.counter"
        />
        <small id="giveawayHelp" class="form-text text-muted">
          Remember to provide a description of what is being given away in the description below.
        </small>
      </div>
    </fieldset>

    <fieldset :disabled="isLocked" v-if="intention.type === 'matching'">
      <div class="form-group">
        <label for="i-matching-counter-amount">
          What is the matching donation amount? <span class="text-danger">*</span>
        </label>
        <money
          v-bind="money"
          class="form-control"
          id="i-matching-counter-amount"
          name="counter"
          v-model="intention.counter"
        ></money>
      </div>
    </fieldset>

    <fieldset
      :disabled="isLocked"
      v-if="intention.type === 'target' && meta.tracked_donation_type !== 'all'"
    >
      <div class="form-group">
        <label class="text-sm">
          What is the minimum donation amount you're looking to track?
        </label>
        <money
          v-bind="money"
          class="form-control"
          id="tracked_donation_minimum"
          name="tracked_donation_minimum"
          v-model="meta.tracked_donation_minimum"
          @keyup="onTrackingChange"
        ></money>
      </div>

      <div class="form-group">
        <label class="text-sm">
          How many {{ meta.tracked_donation_type }} donations do you want to target?
        </label>
        <input
          class="form-control"
          type="number"
          name="tracked_donation_count"
          id="tracked_donation_count"
          v-model="meta.tracked_donation_count"
          @keyup="onTrackingChange"
        />
      </div>
    </fieldset>

    <fieldset :disabled="isLocked">
      <div class="form-group">
        <label for="i-description">Description <span class="text-danger">*</span></label>
        <textarea
          class="form-control"
          id="i-description"
          name="description"
          aria-describedby="descriptionHelp"
          required
          v-model="intention.description"
        >
        </textarea>
        <small id="descriptionHelp" class="form-text text-muted">
          A brief description, if necessary, about the above.
        </small>
      </div>
    </fieldset>

    <div class="form-row justify-content-between">
      <div class="col-auto">
        <button type="submit" class="btn btn-success" :disabled="isLocked">
          <font-awesome-icon icon="save" /> Save
        </button>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-outline-danger"
          :disabled="isLocked"
          @click="$emit('cancel')"
        >
          <font-awesome-icon icon="window-close" /> Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
/*global $*/
import * as _ from 'lodash';
import { Money3Component } from 'v-money3';
import formHelpers from 'mixins/formHelpers';
import FormError from 'components/forms/FormError';

export default {
  name: 'IntentionForm',
  components: { FormError, money: Money3Component },
  mixins: [formHelpers],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    promotion: {
      type: Object,
      required: true,
    },
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      allowedFields: [
        'campaign_id',
        'promotion_id',
        'title',
        'description',
        'type',
        'countable',
        'counter',
        'starts_at',
        'ends_at',
        'meta',
      ],
      error: false,
      intention: {},
      isLocked: false,
      isLoading: false,
      money: {
        precision: 0,
        prefix: '$ ',
        masked: true,
        min: 1,
      },
      meta: {
        tracked_donation_type: 'all',
        tracked_donation_minimum: 0,
        tracked_donation_count: '',
      },
    };
  },
  computed: {
    goalCounterLabel() {
      return this.intention.countable === 'amount'
        ? 'Total $ Amount Collected'
        : 'Total # of Donations Collected';
    },
  },
  created() {
    this.intention = this.initializeIntention();

    if (this.initial.meta && Object.keys(this.initial.meta).length) {
      this.meta = Object.assign(this.meta, this.initial.meta);
    }

    this.$nextTick(() => {
      $('[data-toggle="popover"]').popover();
    });
  },
  methods: {
    lockForm() {
      this.isLocked = true;
    },
    initializeIntention() {
      // console.log(
      //   'IntentionForm initializeIntention()',
      //   this.campaign,
      //   this.promotion,
      //   this.initial
      // );
      const defaultIntention = {
        campaign_id: this.campaign.campaign_id
          ? this.campaign.campaign_id
          : this.promotion.campaign_id,
        promotion_id: this.promotion.promotion_id,
        title: this.promotion.title,
        description: '',
        type: 'target',
        countable: 'amount',
        counter: 0,
        starts_at: null,
        ends_at: null,
        meta: this.meta,
      };

      return Object.assign(defaultIntention, this.initial);
    },
    handleSubmit() {
      // console.log('IntentionForm handleSubmit()', this.intention);
      // Lock the buttons
      this.lockForm();

      const payload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          // We are setting 'PUT' here because we are replacing the entire object. Future iterations may only adjust
          // some parts of the model.
          method: 'PUT',
        },
      };
      // Set the URL to either create or update
      let url;
      if (this.isNew()) {
        url = 'intentions/create';
        payload.options.method = 'POST';
      } else {
        url = 'intentions/update';
        payload.options.id = this.intention.intention_id;
        payload.options.method = 'PUT';
      }

      // Send to action
      this.$store
        .dispatch(url, payload)
        .then(() => {
          // on success, close modal, unlock buttons
          // console.log('IntentionForm handleSubmit() then', response);
          // this.modalClose();
          this.$emit('complete');
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('IntentionForm handleSubmit() then', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.unlockForm();
        });
    },
    modalClose() {
      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    onCountableChange(event) {
      // console.log('IntentionForm onCountableChange()', event.target.value, this.intention.counter);
      // changing types sometimes requires resetting things
      switch (event.target.value) {
        case 'amount':
          // this.intention.counter = 'amount';
          break;
        case 'donors':
          this.intention.counter = Number(
            this.intention.counter.replace(/\s/g, '').replace('$', '').replace(/,/g, '')
          );
          break;
      }
    },
    onTrackingChange(event) {
      const counter = Number(this.intention.counter.toString().replace(/\D/g, ''));
      const minimum = Number(this.meta.tracked_donation_minimum.toString().replace(/\D/g, ''));
      const target = Number(this.meta.tracked_donation_count);
      // console.log('IntentionForm methods onTrackingChange()', event.target, { counter, minimum, target });

      if (event.target.name === 'counter' && minimum > 0) {
        this.meta.tracked_donation_count = counter / minimum;
      } else if (event.target.name === 'tracked_donation_minimum' && counter > 0) {
        this.meta.tracked_donation_count = counter / minimum;
      } else if (event.target.name === 'tracked_donation_count' && minimum > 0) {
        this.intention.counter = minimum * target;
      }
    },
    onTypeChange(event) {
      // console.log('IntentionForm onTypeChange()', event.target.value);
      // changing types sometimes requires resetting things
      switch (event.target.value) {
        case 'target':
          this.intention.countable = 'amount';
          break;
        case 'giveaway':
          this.intention.countable = 'products';
          break;
        case 'matching':
          this.intention.countable = 'match';
          break;
      }
      this.intention.counter = 0;
    },
    cleanMeta() {
      const numberKeys = ['tracked_donation_minimum', 'tracked_donation_count'];

      numberKeys.forEach((key) => {
        // console.log('IntentionForm methods cleanMeta() :key :meta', this.meta[key]);
        this.meta[key] = _.isNumber(this.meta[key])
          ? this.meta[key]
          : Number(this.meta[key].replace(/\s/g, '').replace('$', '').replace(/,/g, ''));
      });

      return this.meta;
    },
    prepareData() {
      // we need to make sure the counter is a number
      if (this.intention.countable === 'amount' || this.intention.countable === 'match') {
        this.intention.counter = _.isNumber(this.intention.counter)
          ? this.intention.counter
          : Number(this.intention.counter.replace('$ ', '').replace(/,/g, ''));
      }

      // we need to make sure meta data is set as JSON string
      this.intention.meta = JSON.stringify(this.cleanMeta());

      return this.intention;
    },
    unlockForm() {
      this.isLocked = false;
    },
  },
};
</script>
