<template>
  <div class="row">
    <div class="col-4 donation-stats">
      <h5 class="donation-stats-label">Goal</h5>
      <p class="donation-stats-number">{{ formatAmountStat(goal) }}</p>
    </div>
    <div class="col-4 donation-stats">
      <h5 class="donation-stats-label">Donated Amount</h5>
      <p class="donation-stats-number">{{ formatAmountStat(amount) }}</p>
    </div>
    <div class="col-4 donation-stats">
      <h5 class="donation-stats-label">Num of Donations</h5>
      <p class="donation-stats-number">{{ formatNumberStat(donations) }}</p>
    </div>
  </div>
</template>

<script>
import hasStatistics from 'mixins/hasStatistics';

export default {
  name: 'CardTotalsHorizontal',
  mixins: [hasStatistics],
  props: {
    goal: {
      type: Number,
      required: false,
    },
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
    donations: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
