<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <button
      v-if="can('store', 'promotions')"
      class="btn btn-sm btn-success mr-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-promotion-form"
    >
      <font-awesome-icon icon="plus-circle" /> Create Promotion
    </button>
    <router-link
      v-if="can('view', 'campaigns') && can('index', 'promotions')"
      class="btn btn-sm btn-primary mr-2"
      :to="{ name: 'campaignPromotions', params: { id: campaign.campaign_id } }"
    >
      <font-awesome-icon icon="clipboard-list" /> View All Promotions
    </router-link>
    <router-link
      v-if="can('view', 'campaigns') && can('index', 'donations')"
      class="btn btn-sm btn-primary mr-2"
      :to="{ name: 'campaignDonations', params: { id: campaign.campaign_id } }"
    >
      <font-awesome-icon icon="gifts" /> View All Donations
    </router-link>
    <router-link
      v-if="can('view', 'campaigns') && can('index', 'pledges')"
      class="btn btn-sm btn-primary mr-2"
      :to="{ name: 'campaignPledges', params: { id: campaign.campaign_id } }"
    >
      <font-awesome-icon icon="handshake-alt" /> View All Pledges
    </router-link>
    <button
      v-if="can('update', 'campaigns')"
      class="btn btn-sm btn-secondary ml-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-campaign-logo"
    >
      <font-awesome-icon icon="image" /> Upload Logo
    </button>
    <button
      v-if="can('update', 'campaigns')"
      class="btn btn-sm btn-secondary ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-campaign-form"
      title="Edit Campaign"
    >
      <font-awesome-icon icon="edit" /> Edit
    </button>
    <button
      v-if="can('destroy', 'campaigns')"
      class="btn btn-sm btn-danger ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-campaign-delete"
      title="Delete Campaign"
    >
      <font-awesome-icon icon="trash" />
    </button>
  </nav>
</template>
<script>
export default {
  name: 'CampaignNavigation',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
};
</script>
