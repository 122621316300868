<template>
  <div class="card mb-4 w-100" :class="{ 'border-primary': isCurrent }">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="my-4" />
    <template v-else>
      <div class="card-body">
        <h5 class="card-title">
          <router-link :to="{ name: 'promotion', params: { id: promotion.promotion_id } }">
            {{ promotion.title }}
          </router-link>
        </h5>
        <p class="card-text">
          {{ promotion.description }}
        </p>
        <p class="card-text" v-if="showCampaign"><strong>Campaign</strong>: {{ campaign.title }}</p>
      </div>

      <div class="card-body">
        <CardTotalsHorizontal
          :amount="statistics.total_sum"
          :donations="statistics.donations_count"
        />
        <FromTo :starts_at="promotion.starts_at" :ends_at="promotion.ends_at" show="time" />
      </div>

      <div class="card-footer">
        <router-link
          :to="{ name: 'promotion', params: { id: promotion.promotion_id } }"
          class="btn btn-primary btn-sm mr-2"
        >
          View
        </router-link>
        <small class="text-muted">
          Updated:
          <span :title="fullDateTime(promotion.updated_at)">{{
            timeAgo(promotion.updated_at)
          }}</span>
        </small>
      </div>
    </template>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import CardTotalsHorizontal from 'components/CardTotalsHorizontal';
import FromTo from 'components/FromTo';
import Error from 'components/Error';
import Loading from 'components/Loading';

export default {
  name: 'PromotionCardExtended',
  components: { CardTotalsHorizontal, FromTo, Error, Loading },
  mixins: [hasTimeDate, helpers],
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    showCampaign: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isCurrent() {
      // Is this Promotion currently running?
      return this.isBetween(this.promotion.starts_at, this.promotion.ends_at);
    },
    // statistics() {
    //   return this.promotion.statistics ?? {};
    // },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      statistics: {},
      intentions: [],
    };
  },
  created() {
    if (
      !Object.prototype.hasOwnProperty.call('statistics', this.promotion) ||
      !Object.prototype.hasOwnProperty.call('intentions', this.promotion)
    ) {
      // console.log('PromotionCardExtended created() :loggedIn', this.loggedIn);
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      const params = {
        id: this.promotion.promotion_id,
        params: {
          with: 'campaign;statistics;intentions',
        },
      };

      // console.log('PromotionCardExtended methods fetchData() :params', params);

      this.$store
        .dispatch('promotions/get', params)
        .then((response) => {
          // console.log('PromotionCardExtended fetchData() promotions/get then :response', response);
          this.isLoading = false;
          this.statistics = response.data.data.statistics;
          this.intentions = response.data.data.intentions;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },
  },
};
</script>
