<template>
  <div>
    <Loading v-if="isLoading" />
    <form v-else @submit.prevent="handleSubmit">
      <FormError v-if="error" :error="error" />
      <fieldset :disabled="isLocked" v-if="page === 1">
        <div class="form-group">
          <label for="pf-title">Promotion Title <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            id="pf-title"
            name="title"
            aria-describedby="titleHelp"
            required
            v-model="promotion.title"
          />
          <small id="titleHelp" class="form-text text-muted">
            The title of the promotion that will be shown throughout the application.
          </small>
        </div>

        <div class="form-group">
          <label for="pf-description">Description <span class="text-danger">*</span></label>
          <textarea
            class="form-control"
            id="pf-description"
            name="description"
            aria-describedby="descriptionHelp"
            required
            v-model="promotion.description"
          >
          </textarea>
          <small id="descriptionHelp" class="form-text text-muted">
            A brief description about this promotion.
          </small>
        </div>

        <div class="form-group">
          <label for="pf-campaign-id">Campaign</label>
          <Select2
            v-model="campaignId"
            :options="campaignOptions"
            :settings="{
              allowClear: true,
              placeholder: '- Select -',
              theme: 'bootstrap4',
              width: '100%',
            }"
            name="campaign_id"
            id="pf-campaign-id"
            required
            @select="handleCampaignSelection($event)"
          />
          <small id="campaignHelp" class="form-text text-muted">
            Which campaign will this be a part of?
          </small>
        </div>

        <fieldset :disabled="!promotion.campaign_id">
          <div class="form-group">
            <label>Promotion Dates/Times <span class="text-danger">*</span></label>
            <div class="row">
              <div class="col-4">
                <label class="text-sm">Choose a Date</label>
                <v-date-picker
                  v-model="dates"
                  :min-date="campaignStartsAt"
                  :max-date="campaignEndsAt"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="pf-date-range-start"
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      required
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="col-4">
                <label class="text-sm">Start Time</label>
                <vue-timepicker
                  id="pf-time-starts-at"
                  name="starts-at"
                  v-model="times.starts_at"
                  format="hh:mm A"
                  :minute-interval="5"
                  auto-scroll
                  placeholder="HH:MM AM/PM"
                  input-class="form-control bg-white"
                  input-width="100%"
                />
              </div>
              <div class="col-4">
                <label class="text-sm">End Time</label>
                <vue-timepicker
                  id="pf-time-ends-at"
                  name="ends-at"
                  v-model="times.ends_at"
                  format="hh:mm A"
                  :minute-interval="5"
                  auto-scroll
                  placeholder="HH:MM AM/PM"
                  input-class="form-control bg-white"
                  input-width="100%"
                />
              </div>
            </div>
            <small id="promotionDatesHelp" class="form-text text-muted">
              How long will this promotion run? Only the dates of the campaign are available to
              choose from.
            </small>
          </div>
        </fieldset>
      </fieldset>

      <div class="form-row justify-content-between" v-if="page === 1">
        <button type="submit" class="btn btn-success" :disabled="isLocked">
          <font-awesome-icon icon="save" /> Save & Continue
        </button>
        <button
          type="button"
          class="btn btn-outline-danger"
          :disabled="isLocked"
          @click="modalClose"
        >
          <font-awesome-icon icon="window-close" /> Cancel
        </button>
      </div>
    </form>

    <IntentionForm
      :is-locked="isLocked"
      v-if="page === 2"
      :campaign="campaign"
      :initial="promotion.intentions ? promotion.intentions[0] : undefined"
      :modal="modal"
      :promotion="promotion"
      @complete="onComplete()"
      @cancel="onCancel()"
    />
  </div>
</template>

<script>
/*global $*/
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import formHelpers from 'mixins/formHelpers';
import hasTimeDate from 'mixins/hasTimeDate';
import VueTimepicker from 'vue3-timepicker';
import IntentionForm from 'components/forms/IntentionForm';
import FormError from 'components/forms/FormError';
import Loading from 'components/Loading';

export default {
  name: 'PromotionForm',
  components: { FormError, IntentionForm, Loading, VueTimepicker },
  mixins: [formHelpers, hasTimeDate],
  props: {
    campaign: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    // Initialize the Promotion
    const initializedPromotion = this.initializePromotion();
    // This is just a shortcut to the Promotion's campaign. Normally only when sent as an edit
    const promotionCampaign = initializedPromotion.campaign || {};

    // Get Campaign Start/End depends on whether passed as prop or inferred from the Promotion
    const campaignStartsAt = this.campaign.starts_at
      ? this.verifyDayjs(this.campaign.starts_at)
      : this.verifyDayjs(promotionCampaign.starts_at);
    const campaignEndsAt = this.campaign.ends_at
      ? this.verifyDayjs(this.campaign.ends_at)
      : this.verifyDayjs(promotionCampaign.ends_at);

    // Get the Promotion Start/End times, if set. Otherwise default to top of the hour
    const promotionStartsAt = initializedPromotion.starts_at
      ? this.verifyDayjs(initializedPromotion.starts_at)
      : dayjs().startOf('hour');
    const promotionEndsAt = initializedPromotion.ends_at
      ? this.verifyDayjs(initializedPromotion.ends_at)
      : dayjs().startOf('hour').add(1, 'hour');

    return {
      allowedFields: [
        'campaign_id',
        'promotion_id',
        'title',
        'description',
        'type',
        'starts_at',
        'ends_at',
      ],
      calendarAttributes: [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
        {
          highlight: false,
          dates: [
            {
              start: campaignStartsAt.toDate(),
              end: campaignEndsAt.toDate(),
            },
          ],
        },
      ],

      // the campaign that is selected from the Campaign selection box rather than passed as a prop, or from the promotion
      campaignSelected: {},
      dates: promotionStartsAt.toDate(),
      error: false,
      errorCampaign: false,
      isLoading: false,
      isLoadingCampaign: false,
      isLocked: false,
      page: 1,
      promotion: initializedPromotion,
      promotionCampaign: {},
      times: {
        starts_at: {
          hh: promotionStartsAt.format('hh'),
          mm: promotionStartsAt.format('mm'),
          A: promotionStartsAt.format('A'),
        },
        ends_at: {
          hh: promotionEndsAt.format('hh'),
          mm: promotionEndsAt.format('mm'),
          A: promotionEndsAt.format('A'),
        },
      },
    };
  },
  created() {
    // console.log('PromotionForm created()');
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  computed: {
    ...mapGetters('campaigns', { campaigns: 'getAll' }),
    campaignEndsAt() {
      if (this.campaign.ends_at) {
        return this.campaign.ends_at;
      }

      if (this.promotion.campaign) {
        return this.promotion.campaign.ends_at;
      }

      if (this.campaignSelected) {
        return this.campaignSelected.ends_at;
      }

      return '';
    },
    campaignStartsAt() {
      if (this.campaign.starts_at) {
        return this.campaign.starts_at;
      }

      if (this.promotion.campaign) {
        return this.promotion.campaign.starts_at;
      }

      if (this.campaignSelected) {
        return this.campaignSelected.starts_at;
      }

      return '';
    },
    watch: {
      range(newValue) {
        if (newValue) {
          this.onDateChange(newValue);
        }
      },
    },
    campaignOptions() {
      const campaignOptions = [];

      this.campaigns.forEach((campaign) => {
        const starts_at = dayjs(campaign.starts_at).format('M/D');
        const ends_at = dayjs(campaign.ends_at).format('M/D');
        campaignOptions.push({
          id: campaign.campaign_id,
          text: `${campaign.title} (${starts_at}-${ends_at})`,
          startsAt: campaign.starts_at,
          endsAt: campaign.ends_at,
        });
      });

      return campaignOptions;
    },
    campaignId() {
      return this.promotion.campaign_id.toString();
    },
  },
  methods: {
    handleCampaignSelection(campaign) {
      const { id, disabled, startsAt: starts_at, endsAt: ends_at } = campaign;
      this.campaignSelected = {
        id,
        disabled,
        starts_at,
        ends_at,
      };

      this.dates = starts_at;
    },
    fetchData() {
      this.error = null;
      this.isLoading = true;

      // Get the Campaigns for the dropdown. We get "all" because there shouldn't be that many.
      // #TODO maybe filter this by current and upcoming?
      this.$store
        .dispatch('campaigns/getAll', { params: { page: 1, per_page: -1 } })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    lockForm() {
      this.isLocked = true;
    },
    initializeCampaign() {
      this.errorCampaign = null;
      this.isLoadingCampaign = true;

      // Get the Campaigns for the dropdown. We get "all" because there shouldn't be that many.
      // #TODO maybe filter this by current and upcoming?
      this.$store
        .dispatch('campaigns/get', { id: this.promotion.campaign_id })
        .then(() => {
          this.isLoadingCampaign = false;
        })
        .catch((error) => {
          this.errorCampaign = this.$errorProcessor(error);
        });
    },
    initializePromotion() {
      const defaultPromotion = {
        campaign_id: this.campaign.campaign_id ?? null,
        title: '',
        description: '',
        starts_at: null,
        ends_at: null,
      };

      // console.log('PromotionForm methods initializePromotion() defaultPromotion', defaultPromotion);

      return Object.assign(defaultPromotion, this.initial);
    },
    handleSubmit() {
      // console.log('PromotionForm methods handleSubmit()', this.promotion);
      // Lock the buttons
      this.lockForm();

      const payload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          // We are setting 'PUT' here because we are replacing the entire object. Future iterations may only adjust
          // some parts of the model.
          method: 'PUT',
        },
      };
      // Set the URL to either create or update
      let url;
      if (this.isNew()) {
        url = 'promotions/create';
      } else {
        url = 'promotions/update';
        payload.options.id = this.promotion.promotion_id ?? null;
      }

      // Send to action
      this.$store
        .dispatch(url, payload)
        .then((response) => {
          // on success, close modal, unlock buttons
          // console.log('PromotionForm handleSubmit() then', response);
          // #TODO store the Promotion response into the data.promotion
          this.promotion.promotion_id = response.data.data.promotion_id;
          this.promotion.campaign_id = response.data.data.campaign_id;

          if (_.has(this.campaign, 'campaign_id')) {
            // #TODO Commented this out because the linter is complaining about modifying a prop. Why are we trying to edit this?
            // this.campaign.campaign_id = this.promotion.campaign_id;
          }

          this.page = 2;
        })
        .catch((error) => {
          // on error, highlight errors
          console.log('PromotionForm handleSubmit() then', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.unlockForm();
        });
    },
    modalClose() {
      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
        this.$emit('close');
      }
    },
    onCancel() {
      // reset the form
      this.resetForm();
      // close the modal
      this.modalClose();
    },
    onComplete() {
      // reset the form
      this.resetForm();
      // close the modal
      this.modalClose();
      // emit complete
      this.$emit('complete');
    },
    onDateChange(date) {
      if (date) {
        // console.log('PromotionForm methods onDateChange() :date', date);
        this.promotion.starts_at = dayjs(date).startOf('day').toISOString();
        this.promotion.ends_at = dayjs(date).endOf('day').toISOString();
      }
    },
    prepareData() {
      // We just need to make sure the dates & times are formatted
      // Get the date
      // const date = this.promotion.starts_at ? dayjs(this.promotion.starts_at) : dayjs(this.dates);
      const date = dayjs(this.dates);
      // get the start time
      const starts_at = this.convert12To24(this.times.starts_at);
      // get the end time
      const ends_at = this.convert12To24(this.times.ends_at);

      this.promotion.starts_at = date
        .clone()
        .set('hour', starts_at.hh)
        .set('minute', starts_at.mm)
        .toISOString();
      this.promotion.ends_at = date
        .clone()
        .set('hour', ends_at.hh)
        .set('minute', ends_at.mm)
        .toISOString();

      return this.promotion;
    },
    resetForm() {
      this.promotion = this.initializePromotion();
      this.isLoading = false;
      this.isLocked = false;
      this.error = false;
      this.page = 1;
    },
    unlockForm() {
      this.isLocked = false;
    },
  },
};
</script>

<style>
@import '~vue3-timepicker/dist/VueTimepicker.css';
</style>
