<template>
  <span :class="classes">
    <font-awesome-icon icon="thumbs-up" @click="toggleFavorite" />
  </span>
</template>

<script>
export default {
  name: 'FavoriteComment',
  props: {
    donation_id: {
      type: Number,
      required: true,
    },
    donor_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      error: false,
    };
  },
  computed: {
    classes() {
      const classes = ['favorite-comment'];

      if (this.isActive) {
        classes.push('is-active');
      }
      if (this.isLoading) {
        classes.push('is-loading');
      }

      return classes.join(' ');
    },
    isActive() {
      return this.favoriteId !== undefined;
    },
    favoriteId() {
      return this.$store.getters['favoriteComments/isFavorite'](this.donation_id);
    },
  },
  methods: {
    toggleFavorite() {
      this.isLoading = true;

      let action, payload;

      if (this.isActive) {
        action = 'destroy';
        payload = {
          id: this.favoriteId ? this.favoriteId.favorite_comments_id : undefined,
        };
      } else {
        action = 'create';
        payload = {
          donation_id: this.donation_id,
          donor_id: this.donor_id,
        };
      }

      this.$store
        .dispatch(`favoriteComments/${action}`, payload)
        .then((response) => {
          console.log('FavoriteComment toggleFavorite() then', response);
        })
        .catch((error) => {
          console.error('FavoriteComment toggleFavorite() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
