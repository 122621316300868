<template>
  <div>
    <div class="row" v-if="!listOnly">
      <div class="col">
        <!-- #TODO pass along existing Q, DateRange, and date boundaries-->
        <DonationsNavigation
          :has-donations="hasDonations"
          @includeDeleted="includeDeleted"
          @searchQuery="searchQuery"
        />
      </div>
    </div>
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="mt-4" />
    <template v-else>
      <div class="row mb-2" v-if="!listOnly">
        <div :class="{ 'col-sm-10': downloadAllowed, 'col-sm-12': !downloadAllowed }">
          <PaginationResults
            title-suffix="donations"
            :total="meta.pagination.total"
            :current-page="pageNum"
            :total-pages="meta.pagination.total_pages"
            :per-page="perPage"
            @searchQuery="searchQuery"
          />
        </div>
        <div class="col-sm-2 text-right" v-if="downloadAllowed">
          <a :href="downloadAllUrl" class="btn btn-outline-primary" target="_blank">
            <font-awesome-icon icon="download" /> Download All
          </a>
        </div>
      </div>
      <div class="row" v-if="!listOnly">
        <div class="col-12">
          <table
            :class="{
              'donations-list': true,
              table: true,
              'table-striped': striped && hasDonations,
              'table-hover': hover,
            }"
          >
            <thead class="thead-dark">
              <tr>
                <th v-if="can('index', 'favorite_comments')"></th>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Form</th>
                <th scope="col">Amount</th>
                <th scope="col"></th>
                <th scope="col">Received</th>
                <th scope="col">Comments</th>
                <th scope="col" style="text-align: center">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!hasDonations">
                <td :colspan="can('index', 'favorite_comments') ? 9 : 8">
                  There are no donations.
                </td>
              </tr>
              <DonationsListRow
                v-else
                v-for="donation in donations"
                :key="donation.donation_id"
                :donation="donation"
                :campaign="campaign"
                @deleteDonation="deleteDonation"
                @restoreDonation="restoreDonation"
              />
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        v-if="!listOnly"
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { axiosInstance as axios, queryParams } from '../../plugins/axios';
import hasTimeDate from 'mixins/hasTimeDate';
import paginationFunctions from 'mixins/paginationFunctions';
import DonationsListRow from 'components/donations/DonationsListRow';
import DonationsNavigation from 'components/donations/DonationsNavigation';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PaginationResults from 'components/PaginationResults';
import Pagination from 'components/Pagination';
import hasIntentions from 'mixins/hasIntentions';

export default {
  name: 'DonationsList',
  mixins: [hasIntentions, hasTimeDate, paginationFunctions],
  components: {
    DonationsListRow,
    DonationsNavigation,
    Error,
    Loading,
    Pagination,
    PaginationResults,
  },
  props: {
    campaign: {
      type: Object,
      required: false,
    },
    donor: {
      type: Object,
      required: false,
    },
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    listOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    promotion: {
      type: Object,
      required: false,
    },
    striped: {
      type: Boolean,
      default: false,
      required: false,
    },
    downloadAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      donations: false,
      error: null,
      includeDeletedDonations: 0,
      isLoading: true,
      isLocked: false,
      meta: false,
      pageNum: 1,
      perPage: 12,
      // dates: { start: null, end: null },
    };
  },
  computed: {
    hasDonations() {
      return this.donations.length > 0;
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    dates() {
      return this.$store.getters['search/getRange'];
    },
    downloadAllUrl() {
      let url = 'donations/export';

      const payload = {
        saveToStore: false,
        params: {
          per_page: 0,
        },
      };

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        payload.params.timeslice = dateLimits;
      }

      // Get Any search parameters
      if (this.campaign) {
        url = `campaigns/${this.campaign.campaign_id}/donations/export`;
      }

      const where = [];

      // limit to a certain donor
      if (this.donor) {
        where.push(`donor_id,${this.donor.donor_id}`);
      }

      // limit to recurring=true/false based on promotion
      if (this.promotion && this.promotion.intentions[0] && this.promotion.intentions[0].meta) {
        if (this.promotion.intentions[0].meta.tracked_donation_type === 'single') {
          where.push('recurring,false');
        } else if (this.promotion.intentions[0].meta.tracked_donation_type === 'recurring') {
          where.push('recurring,true');
        }
      }

      if (where.length) {
        payload.params.where = where.join('|');
      }

      if (this.q) {
        payload.params.search = this.q;
      }

      return `${axios.defaults.baseURL}/${url}${queryParams(payload.params)}`;
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  unmounted() {
    // console.log('DonationsList unmounted()');
    this.$store.commit('search/clearSearch');
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      // Set the default URL. This may get changed later if we have a Campaign or Promotion attached
      let url = 'donations/getAll';

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'donor',
      };

      const payload = {
        saveToStore: false,
        refreshFavorites: this.can('index', 'favorite_comments'),
        params: Object.assign(defaultParams, params || {}),
      };

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        payload.params.timeslice = dateLimits;
      }

      // If we have a campaign or promotion (which should all belong to a Campaign), then use the Campaign URL endpoint
      // so that it restricts results to those attached to the campaign
      if (this.campaign || this.promotion) {
        payload.id = this.campaign ? this.campaign.campaign_id : this.promotion.campaign_id;
        url = 'campaigns/getDonations';
      }

      // Add any search parameters to this where Array
      const where = [];

      // limit to a certain donor
      if (this.donor) {
        where.push(`donor_id,${this.donor.donor_id}`);
      }

      // include deleted donations
      if (this.includeDeletedDonations) {
        if (this.includeDeletedDonations == 2) {
          payload.params.withTrashed = true;
        } else if (this.includeDeletedDonations == 1) {
          payload.params.onlyTrashed = true;
        }
      }

      // Set any parameters based on the Promotion
      if (this.promotion && !_.isEmpty(this.intention) && !_.isEmpty(this.intentionMeta)) {
        // limit to recurring=true/false based on promotion
        if (this.intentionMeta.tracked_donation_type === 'single') {
          where.push('recurring,false');
        } else if (this.intentionMeta.tracked_donation_type === 'recurring') {
          where.push('recurring,true');
        }

        // If there is a minimum value, set that here
        if (this.intentionMeta.tracked_donation_minimum > 0) {
          where.push('amount,gte,' + this.intentionMeta.tracked_donation_minimum);
        }
      }

      if (where.length) {
        payload.params.where = where.join('|');
      }

      if (this.q) {
        payload.params.search = this.q;

        const numericFields = ['amount', 'donor.zip'];
        const textFields = [
          'display_as',
          'comments',
          'donor.display_as',
          'donor.first',
          'donor.last',
          'donor.street',
          'donor.state',
          'donor.city',
          'donor.zip',
          'donor.email',
        ];

        if (isNaN(this.q)) {
          payload.params.searchFields = textFields.join(';');
        } else {
          payload.params.searchFields = numericFields.join(';');
        }
      }

      this.$store
        .dispatch(url, payload)
        .then((response) => {
          // console.log('DonationsList fetchData() then', response);
          const { data, meta } = response.data;
          this.donations = data;
          this.meta = meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    /**
     * By default, we want all the donations ever given, so there is no date range set. If there is a Campaign or
     * Promotion passed as a prop, we want to make sure the returned Donations fall in the timeframe of the
     * Campaign/Promotion.
     *
     * A manually set date/time in the UI would take precedence over any dates set in the
     * Campaign/Promotion. The UI must ensure that those manual dates dont go outside the bounds of the
     * Campaign/Promotion if they were passed as props.
     *
     * @return {boolean}
     */
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let starts_at = false;
      let ends_at = false;

      if (this.dates.start && this.dates.end) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        // ({ starts_at, ends_at } = this.dates);
        starts_at = this.dates.start;
        ends_at = this.dates.end;
      }

      // See if there is a date range set in either Campaign or Promotion that was passed as a prop
      // Use these dates to narrow the scope of the selected dates
      if (this.campaign) {
        // If there is a campaign or promotion, start there
        if (starts_at) {
          // Pick the latter of the selected start date or the campaign start date
          starts_at = this.verifyDayjs(this.campaign.starts_at).isAfter(
            this.verifyDayjs(starts_at)
          );
        }
        ({ starts_at, ends_at } = this.campaign);
      }

      if (this.promotion) {
        // Start with the most specific, manually set dates
        ({ starts_at, ends_at } = this.promotion);
      }

      if (starts_at && ends_at) {
        if (!(this.campaign || this.promotion)) {
          starts_at = this.formatISODate(starts_at, true);
          ends_at = this.formatISODate(ends_at, true);
        } else {
          starts_at = this.formatISODate(starts_at);
          ends_at = this.formatISODate(ends_at);
        }
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${starts_at}|${ends_at}`;
      }

      return limits;
    },
    includeDeleted(include) {
      // console.log('DonationsList includeDeleted()', {
      //   before: this.includeDeletedDonations,
      //   after: include,
      // });
      this.includeDeletedDonations = include;

      this.fetchData();
    },
    searchQuery() {
      // console.log('DonationsList searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
    deleteDonation(id) {
      // console.log('DonationsList methods deleteDonation() :id', id);
      // Lock the buttons
      this.isLocked = true;

      const payload = { id };

      // console.log('DonationsList methods deleteDonation() before :payload', payload);

      // Send to action
      this.$store
        .dispatch('donations/destroy', payload)
        .then(() => {
          // console.log('DonationsList methods deleteDonation() then', response);
          this.fetchData();
        })
        .catch((error) => {
          console.error('DonationsList methods deleteDonation() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    restoreDonation(id) {
      // console.log('DonationsList methods restoreDonation() :id', id);
      // Lock the buttons
      this.isLocked = true;

      const payload = { id };

      // console.log('DonationsList methods restoreDonation() before :payload', payload);

      // Send to action
      this.$store
        .dispatch('donations/restore', payload)
        .then(() => {
          // console.log('DonationsList methods restoreDonation() then', response);
          this.fetchData();
        })
        .catch((error) => {
          console.error('DonationsList methods restoreDonation() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
  },
};
</script>

<style></style>
