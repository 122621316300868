<template>
  <div class="container app-content pt-4">
    <Loading v-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="campaign.title" />

      <CampaignNavigation :campaign="campaign" />

      <div class="row mb-5">
        <div class="col-12">
          <CampaignLogo :campaign="campaign" />

          <h4>Description</h4>
          {{ campaign.description }}
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12 col-md-6">
          <h4>Dates</h4>
          <FromTo :starts_at="campaign.starts_at" :ends_at="campaign.ends_at" show="day" />
        </div>

        <div class="col-12 col-md-6">
          <h4>Totals</h4>
          <CampaignStats :campaign="campaign" :donations="donations" />
        </div>
      </div>

      <template v-if="can('view', 'promotions')">
        <h6 class="mb-0">Promotions</h6>
        <div class="mb-5">
          <Error v-if="errorPromotions" :error="errorPromotions" />
          <div v-else class="row">
            <div class="col-12 col-sm-8">
              <h3>Currently Running</h3>
              <Loading v-if="isLoadingPromotions" />
              <template v-else>
                <PromotionCardExtended
                  :promotion="currentPromotion"
                  :campaign="campaign"
                  :show-campaign="false"
                  v-if="currentPromotion"
                />
                <EmptyCard
                  v-else
                  title="No Promotion is currently running"
                  icon="frown"
                  class="text-primary"
                />
              </template>
            </div>
            <div class="col-12 col-sm-4">
              <h3>Upcoming</h3>
              <Loading v-if="isLoadingPromotions" />
              <template v-else>
                <PromotionCard
                  :promotion="nextPromotion"
                  :campaign="campaign"
                  :show-campaign="false"
                  v-if="nextPromotion"
                />
                <EmptyCard
                  v-else
                  title="No Promotion is scheduled"
                  icon="frown"
                  class="text-primary"
                />
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-if="can('index', 'donations')">
        <h3>Donations</h3>
        <div class="mb-5">
          <Error v-if="errorDonations" :error="errorDonations" />
          <Loading v-else-if="isLoadingDonations" />
          <DonationsList v-else :campaign="campaign" :striped="true" :hover="true" />
        </div>
      </template>

      <template v-if="can('index', 'pledges')">
        <h3>Pledges</h3>
        <Error v-if="errorPledges" :error="errorPledges" />
        <Loading v-else-if="isLoadingPledges" />
        <PledgesList v-else :pledges="pledges" :striped="true" :hover="true" />
      </template>
    </template>

    <Modal id="modal-campaign-form">
      <template v-slot:title>Edit Campaign</template>

      <template v-slot:body>
        <CampaignForm
          v-if="!isLoading"
          :initial="campaign"
          modal="#modal-campaign-form"
          @complete="fetchData()"
        />
      </template>
    </Modal>

    <Modal id="modal-promotion-form">
      <template v-slot:title>Create Promotion</template>

      <template v-slot:body>
        <PromotionForm
          v-if="!isLoading"
          :campaign="campaign"
          modal="#modal-promotion-form"
          @complete="fetchData()"
        />
      </template>
    </Modal>

    <Modal id="modal-campaign-delete">
      <template v-slot:title>Delete Campaign</template>

      <template v-slot:body>
        <CampaignDeleteForm
          v-if="!isLoading"
          :id="Number(campaign.campaign_id)"
          modal="#modal-campaign-delete"
        />
      </template>
    </Modal>

    <Modal id="modal-campaign-logo">
      <template v-slot:title>Upload Campaign Logo</template>

      <template v-slot:body>
        <CampaignLogoForm
          v-if="!isLoading"
          :id="Number(campaign.campaign_id)"
          modal="#modal-campaign-logo"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
/*global $*/
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import CampaignDeleteForm from 'components/forms/CampaignDeleteForm';
import CampaignForm from 'components/forms/CampaignForm';
import CampaignLogo from 'components/campaigns/CampaignLogo';
import CampaignLogoForm from 'components/forms/CampaignLogoForm';
import CampaignNavigation from 'components/campaigns/CampaignNavigation';
import CampaignStats from 'components/stats/CampaignStats';
import DonationsList from 'components/donations/DonationsList';
import PledgesList from 'components/pledges/PledgesList';
import EmptyCard from 'components/status-cards/EmptyCard';
import FromTo from 'components/FromTo';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import PromotionCard from 'components/promotions/PromotionCard';
import PromotionCardExtended from 'components/promotions/PromotionCardExtended';
import PromotionForm from 'components/forms/PromotionForm';

dayjs.extend(isBetween);
export default {
  name: 'CampaignsShow',
  components: {
    CampaignDeleteForm,
    CampaignForm,
    CampaignLogo,
    CampaignLogoForm,
    CampaignNavigation,
    CampaignStats,
    DonationsList,
    PledgesList,
    EmptyCard,
    FromTo,
    Loading,
    Modal,
    PageHeader,
    PromotionCard,
    PromotionCardExtended,
    PromotionForm,
  },
  mixins: [hasStatistics, hasTimeDate],
  props: {
    id: {
      type: Number,
      required: true,
    },
    openCreatePromotion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      promotions: [],
      promotionsMeta: {},
      donations: [],
      donationsMeta: {},
      pledges: [],
      pledgesMeta: {},
      error: null,
      errorPromotions: null,
      errorDonations: null,
      errorPledges: null,
      isLoading: true,
      isLoadingPromotions: true,
      isLoadingDonations: true,
      isLoadingPledges: true,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
    currentPromotion() {
      const current = this.promotions.filter((promotion) => {
        // start < now < end
        const start = this.verifyDayjs(promotion.starts_at);
        const end = this.verifyDayjs(promotion.ends_at);

        return dayjs().isBetween(start, end);
      });

      return current.length >= 1 ? current[0] : false;
    },
    nextPromotion() {
      const next = this.promotions.filter((promotion) => {
        // start > now
        const start = this.verifyDayjs(promotion.starts_at);
        const end = this.verifyDayjs(promotion.ends_at);

        return dayjs().isBefore(start) && dayjs().isBefore(end);
      });

      return next.length >= 1 ? next[0] : false;
    },
  },
  created() {
    // console.log('CampaignsShow created()');
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.openCreatePromotion) {
        $('#modal-promotion-form').modal('show');
      }
    });
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      const campaignParams = {
        id: this.id,
        params: {
          with: 'statistics',
        },
      };

      this.$store
        .dispatch('campaigns/get', campaignParams)
        .then(() => {
          this.isLoading = false;
          this.fetchPromotions();
          this.fetchDonations();
          this.fetchPledges();
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    fetchPromotions() {
      this.errorPromotions = null;
      this.isLoadingPromotions = true;

      const payload = {
        id: this.id,
        params: {
          order_by: 'starts_at',
          order_dir: 'DESC',
          page: 1,
          per_page: 5,
          // timeslice: `starts_at;${this.formatISODate(moment())}|${this.formatISODate(
          //   moment().add('2', 'days')
          // )}`,
          with: 'statistics;intentions',
        },
      };

      this.$store
        .dispatch('campaigns/getPromotions', payload)
        .then((response) => {
          // console.log('CampaignsShow methods fetchPromotions() then', payload, response.data);
          this.promotions = response.data.data;
          this.promotionsMeta = response.data.meta;

          this.isLoadingPromotions = false;
        })
        .catch((error) => {
          this.errorPromotions = this.$errorProcessor(error);
        });
    },
    fetchDonations() {
      this.errorDonations = null;
      this.isLoadingDonations = true;
      const { ends_at, starts_at } = this.campaign;
      const payload = {
        id: this.id,
        params: {
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
        },
        saveToStore: true,
      };

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('CampaignsShow methods fetchDonations() then', payload, response.data);
          this.donations = response.data.data;
          this.donationsMeta = response.data.meta;

          this.isLoadingDonations = false;
        })
        .catch((error) => {
          this.errorDonations = this.$errorProcessor(error);
        });
    },
    fetchPledges() {
      this.errorPledges = null;
      this.isLoadingPledges = true;
      const payload = {
        id: this.id,
        params: {},
        saveToStore: true,
      };

      this.$store
        .dispatch('campaigns/getPledges', payload)
        .then((response) => {
          // console.log('CampaignsShow methods fetchPledges() then', payload, response.data);
          this.pledges = response.data.data;
          this.pledgesMeta = response.data.meta;

          this.isLoadingPledges = false;
        })
        .catch((error) => {
          this.errorPledges = this.$errorProcessor(error);
        });
    },
  },
};
</script>
