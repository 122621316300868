<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-2">
    <div class="form-row">
      <div class="form-select form-select-inline">
        <select
          v-model="deletedDonations"
          class="custom-control custom-select"
          @change="$emit('includeDeleted', deletedDonations)"
        >
          <option value="2" :selected="isSelected(deletedDonations, 2)">Show all donations</option>
          <option value="1" :selected="isSelected(deletedDonations, 1)">
            Show only deleted donations
          </option>
          <option value="0" :selected="isSelected(deletedDonations, 0)">
            Show only non-deleted donations
          </option>
        </select>
      </div>
    </div>

    <SearchForm
      v-if="can('search', 'donations') && hasDonations"
      class="ml-auto"
      model="donation"
      :use-date="true"
      :use-search="true"
      @searchQuery="searchQuery"
    />
  </nav>
</template>

<script>
import SearchForm from 'components/forms/SearchForm';
import search from 'mixins/hasSearch';
import helpers from 'mixins/helpers';

export default {
  name: 'DonationsNavigation',
  mixins: [search, helpers],
  components: { SearchForm },
  props: {
    hasDonations: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      deletedDonations: 2,
    };
  },
};
</script>
