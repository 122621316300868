<template>
  <div class="card-deck error-cards justify-content-center">
    <div class="card error-card text-center mb-2">
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <font-awesome-icon :icon="icon" :size="size" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyCard',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Empty',
    },
    icon: {
      type: String,
      required: false,
      default: 'not-equal',
    },
    size: {
      type: String,
      required: false,
      default: '3x',
    },
  },
};
</script>
