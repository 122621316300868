<template>
  <div v-if="logo" class="campaign-logo bg-light mb-1 ml-1">
    <img :src="logo" class="img-fluid" />
  </div>
</template>

<script>
export default {
  name: 'CampaignLogo',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logo() {
      if (Object.prototype.hasOwnProperty.call(this.campaign, 'logo')) {
        return this.campaign.logo;
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.campaign-logo {
  float: right;
  display: block;
  max-width: 300px;
  width: 100%;
  height: auto;

  .no-logo {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-transform: uppercase;
  }
}
</style>
