<template>
  <table
    :class="{
      'pledges-list': true,
      table: true,
      'table-striped': striped && hasPledges,
      'table-hover': hover,
    }"
  >
    <thead class="thead-dark">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Anon?</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Amount</th>
        <th scope="col">Total</th>
        <!-- <th scope="col">Campaign</th> -->
        <!-- <th scope="col">Promotion</th> -->
      </tr>
    </thead>
    <tbody>
      <tr v-if="!hasPledges">
        <td colspan="7">There are no pledges.</td>
      </tr>
      <PledgesListRow v-else v-for="pledge in pledges" :key="pledge.pledge_id" :pledge="pledge" />
    </tbody>
  </table>
</template>

<script>
import PledgesListRow from 'components/pledges/PledgesListRow';

export default {
  name: 'PledgesList',
  components: { PledgesListRow },
  props: {
    pledges: {
      type: [Object, Array],
      default: () => [],
      required: true,
    },
    campaign: {
      type: Object,
      required: false,
    },
    striped: {
      type: Boolean,
      default: false,
      required: false,
    },
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    hasPledges() {
      return this.pledges.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
// .pledges-list {
//   tbody {
//     tr {
//       cursor: pointer;
//     }
//   }
// }
</style>
